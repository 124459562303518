import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { selectProject } from './redux/reducers/generic/project';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { updateUserIp } from './redux/actions/solo/updateUserIP';
import { updateUserMetadata } from './redux/actions/solo/updateUserMetadata';
import { selectEnterpriseOrganization } from './redux/reducers/enterprise/enterpriseOrganization';

export const AnalyticsWrapper = ({ children }) => {
    const sessionContext = useSessionContext();
    const [searchParams] = useSearchParams();
    const [selineInstance, setSelineInstance] = useState(null);
    const project = useSelector(selectProject);
    const enterpriseOrganization = useSelector(selectEnterpriseOrganization);
    const dispatch = useDispatch();

    useEffect(() => {
        const loadSeline = async () => {
            if (!window.seline) {
                const seline = await import('@seline-analytics/web');
                window.seline = seline;
                setSelineInstance(seline);
                seline.init();
            } else {
                setSelineInstance(window.seline);
            }
        };

        const adParams = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_content',
            'utm_term',
            'gad_source',
            'gad_medium',
            'gad_campaign',
            'gad_content',
            'gad_term',
            'gclid'
        ];
        let adParamsObj = {};
        const existingParams = localStorage.getItem('ad_params');
        if (existingParams) {
            adParamsObj = JSON.parse(existingParams);
        }

        if (searchParams.get('ref')) {
            localStorage.setItem('referralLink', searchParams.get('ref'));
        }

        let hasNewParams = false;
        adParams.forEach(param => {
            if (searchParams.get(param)) {
                adParamsObj[param] = searchParams.get(param);
                hasNewParams = true;
            }
        });

        if (hasNewParams) {
            adParamsObj.utm_url = window.location.href;
            localStorage.setItem('ad_params', JSON.stringify(adParamsObj));
        }

        loadSeline();
    }, []);

    useEffect(() => {
        const updateUserData = async () => {
            if (
                selineInstance &&
                sessionContext?.accessTokenPayload?.email &&
                sessionContext?.accessTokenPayload?.userId
            ) {
                dispatch(updateUserIp(enterpriseOrganization?._id));

                const storedParams = localStorage.getItem('ad_params');
                if (storedParams) {
                    const adParamsObj = JSON.parse(storedParams);
                    const fields = Object.entries(adParamsObj).map(
                        ([key, value]) => ({
                            key,
                            value: String(value)
                        })
                    );

                    if (fields.length > 0) {
                        try {
                            await dispatch(updateUserMetadata(fields));
                            // Clear stored params after successful update
                            // localStorage.removeItem('ad_params');
                        } catch (error) {
                            console.error(
                                'Failed to update user metadata:',
                                error
                            );
                        }
                    }
                }

                selineInstance?.setUser({
                    userId: sessionContext.accessTokenPayload.userId,
                    email: sessionContext.accessTokenPayload.email,
                    name: sessionContext.accessTokenPayload.email
                });
            } else if (project) {
                console.log('Setting user based on project', project.userEmail);
                selineInstance?.setUser({
                    email: project.userEmail
                });
            }
        };

        updateUserData();
    }, [
        selineInstance,
        sessionContext.accessTokenPayload,
        project,
        enterpriseOrganization,
        dispatch
    ]);

    return <>{children}</>;
};
