import { GET_PROJECTS_BY_LEAD_GEN } from '../../../api/graphQL';
import { client } from '../../../api/client';
import {
    fetchProjectsFailure,
    fetchPremiumLeads
} from '../../reducers/generic/projects';
import { projectsLimit } from '../../../constants';

// Action Creators
export const getPremiumLeadsByLeadGen =
    ({
        enterpriseOrganizationId,
        countries,
        tags,
        budgetMin,
        budgetMax,
        skip = 0,
        limit = projectsLimit
    }) =>
    async dispatch => {
        try {
            dispatch(fetchPremiumLeads(null));

            const { data } = await client.query({
                query: GET_PROJECTS_BY_LEAD_GEN,
                variables: {
                    enterpriseOrganizationId,
                    onlyPremiumLeadGenEnabled: true,
                    skip,
                    limit,
                    countries,
                    tags,
                    budgetMin,
                    budgetMax
                },
                fetchPolicy: 'network-only'
            });
            dispatch(fetchPremiumLeads(data.projectsByLeadGen.projects));
        } catch (error) {
            console.log({ error });
            dispatch(fetchProjectsFailure(error));
        }
    };
