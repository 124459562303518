import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SparklesText from '../../Components/magicui/SparklesText';
import WordPullUp from '../../Components/magicui/WordPullUp';
import { Loader2 } from 'lucide-react';
import { InputArea } from './input_area';
import { QuickThirdParty } from '../../Auth/quick-third-party';

export const QuestionExperience = ({
    questionIndex,
    setQuestionIndex,
    tempProjectId,
    setTempProjectId
}) => {
    const [showInput, setShowInput] = useState(true);
    const [restartAnimation, setRestartAnimation] = useState(false);
    const [showTextarea, setShowTextarea] = useState(questionIndex === 0);
    const [startTextUp, setStartTextUp] = useState(questionIndex === 0);
    const [countdown, setCountdown] = useState(null);
    const [loading, setLoading] = useState(null);
    const [answers, setAnswers] = useState([]);
    const [matchWithDev, setMatchWithDev] = useState(null);
    const [urgency, setUrgency] = useState(100);

    useEffect(() => {
        if (!showInput) {
            //document.body.classList.add('no-scroll');
            const timeout = setTimeout(() => {
                setRestartAnimation(true);
                setShowInput(true);
                setShowTextarea(false);
                setStartTextUp(false);
                const restartTimeout = setTimeout(() => {
                    setRestartAnimation(false);
                }, 100); // Short duration to trigger the re-render
                return () => clearTimeout(restartTimeout);
            }, 500); // Duration of the exit animation
            return () => clearTimeout(timeout);
        }
    }, [showInput]);

    const handleWordPullUpAnimationEnd = () => {
        setStartTextUp(true);
        setTimeout(() => {
            setShowTextarea(true);
            setTimeout(() => {
                try {
                    // document.getElementById('mainInput').focus();
                } catch (e) {
                    console.log(e);
                }
            }, 100);
        }, 0.5);
    };

    return (
        <AnimatePresence>
            {!restartAnimation && showInput && (
                <motion.div
                    initial={{
                        opacity: 1,
                        y: 0
                    }}
                    animate={{
                        opacity: 1,
                        y: 0
                    }}
                    exit={{
                        opacity: 0,
                        y: '30vh'
                    }}
                    transition={{
                        duration: 0.5
                    }}
                    className="relative"
                >
                    <QuestionArea
                        handleWordPullUpAnimationEnd={
                            handleWordPullUpAnimationEnd
                        }
                        startTextUp={startTextUp}
                        questionIndex={questionIndex}
                        tempProjectId={tempProjectId}
                        setTempProjectId={setTempProjectId}
                    />
                    {showTextarea && (
                        <TextArea
                            answers={answers}
                            setAnswers={setAnswers}
                            setShowInput={setShowInput}
                            setCountdown={setCountdown}
                            setLoading={setLoading}
                            setQuestionIndex={setQuestionIndex}
                            questionIndex={questionIndex}
                            loading={loading}
                            countdown={countdown}
                            matchWithDev={matchWithDev}
                            setMatchWithDev={setMatchWithDev}
                            urgency={urgency}
                            setUrgency={setUrgency}
                            tempProjectId={tempProjectId}
                            setTempProjectId={setTempProjectId}
                        />
                    )}
                </motion.div>
            )}
        </AnimatePresence>
    );
};
const QuestionArea = ({
    handleWordPullUpAnimationEnd,
    startTextUp,
    questionIndex
}) => {
    let message =
        questionIndex == 0
            ? `What's an idea you dream of building?`
            : questionIndex == 1
              ? 'Great! What email should we send your project to?'
              : 'Thank you! We will send your project to your email shortly.';

    return (
        <>
            <motion.div
                initial={{ y: 0 }}
                animate={{
                    y: questionIndex === 0 ? 0 : startTextUp ? -20 : 50
                }}
                transition={{
                    duration: questionIndex === 0 ? 0 : 0.25
                }}
                className="relative mb-4"
            >
                <SparklesText sparklesCount={8}>
                    <WordPullUp
                        questionIndex={questionIndex}
                        className={`text-4xl mt-[20px] md:mt-[120px] text-center font-medium tracking-[-0.02em] text-white dark:text-white md:text-6xl`}
                        words={message}
                        onAnimationEnd={handleWordPullUpAnimationEnd}
                    />
                </SparklesText>
            </motion.div>
        </>
    );
};

const TextArea = ({
    answers,
    setAnswers,
    setShowInput,
    setCountdown,
    setLoading,
    setQuestionIndex,
    questionIndex,
    loading,
    countdown,
    matchWithDev,
    setMatchWithDev,
    urgency,
    setUrgency,
    tempProjectId,
    setTempProjectId
}) => {
    return (
        <motion.div
            initial={{
                opacity: questionIndex === 0 ? 1 : 0
            }}
            animate={{
                opacity: questionIndex === 0 ? 1 : 1
            }}
            transition={{
                duration: questionIndex === 0 ? 0 : 1
            }}
            className="relative mx-4"
        >
            {loading ? (
                <div className="flex flex-col items-center">
                    <Loader2 className="h-16 w-16 mx-auto text-slate-50 animate-spin text-center" />
                    {countdown !== null && (
                        <div className="text-slate-400 text-sm mt-4">
                            Redirecting you in {countdown}
                            ...
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <InputArea
                        answers={answers}
                        setAnswers={setAnswers}
                        setShowInput={setShowInput}
                        setCountdown={setCountdown}
                        setLoading={setLoading}
                        setQuestionIndex={setQuestionIndex}
                        questionIndex={questionIndex}
                        matchWithDev={matchWithDev}
                        setMatchWithDev={setMatchWithDev}
                        urgency={urgency}
                        setUrgency={setUrgency}
                        tempProjectId={tempProjectId}
                        setTempProjectId={setTempProjectId}
                    />

                    {questionIndex === 0 && (
                        <h1
                            className={`w-fit mx-auto 
        text-center text-sm text-gray-400 mt-2 tracking-wide shadow-lg py-3 px-4 rounded-lg max-w-3xl`}
                        >
                       
                            <span className="font-semibold text-blue-500">Prototype { ' ' }</span> 
                            your idea and {' '}
                            <span className="font-semibold text-blue-500">
                            match { ' '}
                            </span>
                            with top developer agencies ready to execute.
                        </h1>
                    )}

                    {questionIndex === 1 && <QuickThirdParty />}
                </>
            )}
        </motion.div>
    );
};
