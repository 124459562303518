import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { getApiDomain } from '../auth_config';
import { createClient } from 'graphql-ws';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';

let timedOut;

function createRestartableClient(options) {
    let restartRequested = false;
    let restart = () => {
        restartRequested = true;
    };

    //TODO: ENSURE IN THE FUTURE THIS IS HANDLED FOR HUGE LOAD
    const client = createClient({
        ...options,
        keepAlive: 10_000,
        on: {
            ...options.on,
            opened: socket => {
                options.on?.opened?.(socket);
                restart = () => {
                    if (socket.readyState === WebSocket.OPEN) {
                        // if the socket is still open for the restart, do the restart
                        socket.close(4205, 'Client Restart');
                    } else {
                        // otherwise the socket might've closed, indicate that you want
                        // a restart on the next opened event
                        restartRequested = true;
                    }
                };

                // just in case you were eager to restart
                if (restartRequested) {
                    restartRequested = false;
                    restart();
                }
            },
            ping: received => {
                if (!received /* sent */) {
                    timedOut = setTimeout(() => {
                        // a close event `4499: Terminated` is issued to the current WebSocket and an
                        // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
                        // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
                        //
                        // calling terminate is not considered fatal and a connection retry will occur as expected
                        //
                        // see: https://github.com/enisdenjo/graphql-ws/discussions/290
                        console.log('pong timeout');
                        client.terminate();
                    }, 10_000);
                }
            },
            pong: received => {
                if (received) {
                    // received pong, clear the timeout
                    clearTimeout(timedOut);
                }
            }
        }
    });

    return {
        ...client,
        restart: () => restart()
    };
}

// ... existing code ...

export const createApolloClient = (apiKey = null) => {
    if (window.apolloClient) {
        return window.apolloClient; // Return existing client if it exists
    }

    const wsClient = createRestartableClient({
        url:
            getApiDomain().replace('https', 'wss').replace('http', 'ws') +
            '/graphql',
        shouldRetry: () => true,
        retryWait: async () => {
            console.log('Retrying WebSocket connection...');
            await new Promise(resolve => setTimeout(resolve, 5000));
        }
    });

    const wsLink = new GraphQLWsLink(wsClient);

    const httpLink = new HttpLink({
        uri: getApiDomain() + '/graphql',
        headers: apiKey
            ? {
                  'x-api-key': apiKey,
                  'x-parent-domain':
                      document.referrer || document.location.origin
              }
            : {}
    });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);
            return (
                definition.kind === 'OperationDefinition' &&
                definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink
    );

    const client = new ApolloClient({
        link: splitLink,
        cache: new InMemoryCache()
    });

    window.apolloClient = client; // Save the client to window for future use
    return client;
};

export const client = createApolloClient();

export const clientWithAPIKey = apiKey => createApolloClient(apiKey);
