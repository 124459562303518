import {
    createSlice,
    createListenerMiddleware,
    isAnyOf
} from '@reduxjs/toolkit';
import { getGraphData } from '../../../helpers/graph';
import { mapTasksToArchitecture } from '../../../helpers/mapTasksToArchitecture';
import { cloneDeep } from 'lodash';

const initialState = {
    seen: true,
    loading: false,
    currentProjectId: null,
    currentQuestionIndex: null,
    error: null,
    graphVersion: null,
    graphVersionLoading: false,
    graphs: [],
    currentNode: null,
    currentEdge: null,
    panelResized: 0,
    og_graphs: [],
    selectedNode: null,
    uiView: null,
    gettingUIView: false,
    uiViewLoading: false,
    freeNodeLabel: null,
    data: {
        type: 'mvp'
    },
    loadingSoW: {
        mvp: null,
        custom: null
    },
    hideHours: true,
    editMode: false,
    skillLevel: localStorage.getItem('skillLevel') || 'senior',
    loadingMVP: null,
    loadingCustom: null,
    budget: 0,
    budgetFilterActivated:
        localStorage.getItem('budgetFilterActivated') === 'true' || false,
    prices: null,
    price: null,
    quoteMode: false
};

/**
 * Helper function that re-maps tasks to architecture, updates
 * the main graphs, milestones, and freeNodeLabel in the state.
 */
function refreshArchitectureData(state) {
    try {
        let deep_state = cloneDeep(state);
        // Only proceed if we have original milestones in state.data

        const { milestones, graphs } = mapTasksToArchitecture(
            deep_state.data.og_milestones,
            deep_state.skillLevel,
            deep_state.budget,
            deep_state.budgetFilterActivated,
            deep_state.og_graphs
        );
        state.data.milestones = milestones;
        state.graphs = graphs;
        state.freeNodeLabel = findFreeNodeLabel(graphs);
    } catch (error) {
        console.error('Error refreshing architecture data:', error);
    }
}

/**
 * Finds and returns the 'label' of the node connected to
 * the 'core' node on the 'frontend' side, if any.
 */
function findFreeNodeLabel(graphs) {
    const frontendGraph = graphs.find(g => g.side === 'frontend');
    if (!frontendGraph) return null;

    const coreNode = frontendGraph.nodes.find(
        node => node.id.toLowerCase() === 'core'
    );
    if (!coreNode) return null;

    const connectedEdge = frontendGraph.edges.find(
        edge => edge.source === 'core'
    );
    if (!connectedEdge) return null;

    const connectedNode = frontendGraph.nodes.find(
        node => node.id === connectedEdge.target
    );
    if (!connectedNode) return null;

    return connectedNode.data.label;
}

export const projectArchitectureSlice = createSlice({
    name: 'projectArchitecture',
    initialState,
    reducers: {
        setPanelResized: state => {
            state.panelResized += 1;
        },
        setProjectArchitectureSeen: (state, action) => {
            state.seen = action.payload;
        },
        setSelectedNode: (state, action) => {
            state.selectedNode = action.payload;
        },
        gettingUIView: (state, action) => {
            state.gettingUIView = action.payload;
        },
        setUIView: (state, action) => {
            state.uiView = action.payload;
        },
        setUIViewStatus: (state, action) => {
            if (state.uiView) {
                state.uiView.status = action.payload;
            }
        },
        waitingForProjectArchitecture: (state, action) => {
            window.localStorage.setItem(
                'waitingForProjectArchitecture',
                Date.now().toString()
            );
            state.loading = action.payload;
        },
        waitingForUIView: (state, action) => {
            state.uiViewLoading = action.payload;
        },
        setGraphVersionLoading: (state, action) => {
            state.graphVersionLoading = action.payload;
        },
        clearStateProjectArchitecture: state => {
            state.graphs = [];
            state.error = null;
            state.loading = false;
            state.currentQuestionIndex = null;
            state.currentProjectId = null;
            state.graphVersion = null;
            state.currentNode = null;
            state.currentEdge = null;
            state.panelResized = 0;
            state.selectedNode = null;
            state.uiView = null;
            state.gettingUIView = false;
            state.uiViewLoading = false;
            state.freeNodeLabel = null;
        },
        setGraph: (state, action) => {
            let graph = action.payload;

            if (graph.version) {
                state.graphVersion = graph.version;
            }
            graph = getGraphData(graph);

            // Update og_graphs with the new or replaced graph
            let isUpdated = false;
            const updatedOgGraphs = cloneDeep(state.og_graphs).map(g => {
                if (g.side === graph.side) {
                    isUpdated = true;
                    return graph;
                }
                return g;
            });
            if (!isUpdated) {
                updatedOgGraphs.push(graph);
            }
            state.og_graphs = updatedOgGraphs;

            // Refresh architecture data
            refreshArchitectureData(state);
        },
        setGraphVersion: (state, action) => {
            state.graphVersion = action.payload;
        },
        setProjectArchitecture: (state, action) => {
            try {
           
                const ogGraphs = action.payload.graphs.map(graph =>
                    getGraphData(graph)
                );
                state.og_graphs = ogGraphs;

                if (ogGraphs.length && ogGraphs[0].version) {
                    state.graphVersion = ogGraphs[0].version;
                }

                // Refresh architecture data
                refreshArchitectureData(state);
            } catch (error) {
                console.error('Error setting project architecture:', error);
            }
        },
        setPrices: (state, action) => {
            state.prices = action.payload;
        },
        setPrice: (state, action) => {
            state.price = action.payload;
        },
        setSow: (state, action) => {
            const sow = cloneDeep(action.payload);
            if (sow) {
                sow.og_milestones = sow.milestones;
                state.data = sow;
                refreshArchitectureData(state);
            }
        },
        setEditMode: (state, action) => {
            state.editMode = action.payload;
        },
        setSkillLevel: (state, action) => {
            state.skillLevel = action.payload;
            localStorage.setItem('skillLevel', action.payload);
            refreshArchitectureData(state);
        },
        setHideHours: (state, action) => {
            state.hideHours = action.payload;
        },
        waitingForSowMVP: (state, action) => {
            state.loadingMVP = action.payload;
        },
        waitingForSowCustom: (state, action) => {
            state.loadingCustom = action.payload;
        },
        updateSowWithBudget: (state, action) => {
            const { budget } = action.payload;
            state.budget = budget;
            refreshArchitectureData(state);
        },
        toggleQuoteMode: (state, action) => {
            state.quoteMode = action.payload;
        },
        setBudgetFilterActivated: (state, action) => {
            state.budgetFilterActivated = action.payload;
            localStorage.setItem(
                'budgetFilterActivated',
                action.payload.toString()
            );
            refreshArchitectureData(state);
        }
    }
});

export const {
    setGraph,
    waitingForProjectArchitecture,
    waitingForUIView,
    setProjectArchitecture,
    clearStateProjectArchitecture,
    setProjectArchitectureSeen,
    setSelectedNode,
    setUIView,
    setGraphVersionLoading,
    setPanelResized,
    setGraphVersion,
    gettingUIView,
    setEditMode,
    setHideHours,
    setSow,
    setShowSowModal,
    waitingForSowMVP,
    waitingForSowCustom,
    setSkillLevel,
    updateSowWithBudget,
    setBudgetFilterActivated,
    setPrices,
    toggleQuoteMode,
    setPrice,
    setUIViewStatus
} = projectArchitectureSlice.actions;

// -------------- SELECTORS --------------
export const selectProjectArchitecture = state => state.projectArchitecture;
export const selectProjectArchitectureSeen = state =>
    state.projectArchitecture.seen;
export const selectProjectArchitectureLoading = state =>
    state.projectArchitecture.loading;
export const selectCurrentNode = state => state.projectArchitecture.currentNode;
export const selectCurrentEdge = state => state.projectArchitecture.currentEdge;
export const selectPanelResized = state =>
    state.projectArchitecture.panelResized;
export const selectGraphVersion = state =>
    state.projectArchitecture.graphVersion;
export const selectGraphVersionLoading = state =>
    state.projectArchitecture.graphVersionLoading;
export const selectSelectedNode = state =>
    state.projectArchitecture.selectedNode;
export const selectUIView = state => state.projectArchitecture.uiView;
export const selectGettingUIView = state =>
    state.projectArchitecture.gettingUIView;
export const selectUIViewLoading = state =>
    state.projectArchitecture.uiViewLoading;
export const selectFreeNodeLabel = state =>
    state.projectArchitecture.freeNodeLabel;
export const selectSow = state => state.projectArchitecture.data;
export const selectLoadingSowMVP = state =>
    state.projectArchitecture.loadingMVP;
export const selectLoadingSowCustom = state =>
    state.projectArchitecture.loadingCustom;
export const selectSkillLevel = state => state.projectArchitecture.skillLevel;
export const selectHideHours = state => state.projectArchitecture.hideHours;
export const selectEditMode = state => state.projectArchitecture.editMode;
export const selectPrices = state => state.projectArchitecture.prices;
export const selectBudgetFilterActivated = state =>
    state.projectArchitecture.budgetFilterActivated;
export const selectQuoteMode = state => state.projectArchitecture.quoteMode;
export const selectPrice = state => state.projectArchitecture.price;
export const selectGraphs = state => state.projectArchitecture.graphs;

// -------------- REDUCER --------------
export default projectArchitectureSlice.reducer;

// -------------- LISTENER MIDDLEWARE --------------
export const projectArchitectureListenerMiddleware = createListenerMiddleware();

projectArchitectureListenerMiddleware.startListening({
    matcher: isAnyOf(
        action => action.type === 'project/setProjectBudget',
        action => action.type === 'project/fetchProjectSuccess'
    ),
    effect: (action, listenerApi) => {
        const state = listenerApi.getState();
        const budget =
            action.type === 'project/setProjectBudget'
                ? action.payload
                : state.project.data.budget;

        listenerApi.dispatch(updateSowWithBudget({ budget }));
    }
});
