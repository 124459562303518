import { Share2Icon } from 'lucide-react';

export function ArchitectureGeneration() {
    return {
        Icon: Share2Icon,
        name: 'Technical Architecture',
        description:
            'Instantly visualize your high-level architecture, as pre.dev can map any new or existing products.',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        className: 'col-span-3 lg:col-span-1',
        background: (
            <div className="absolute h-[200] w-[200] border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_70%)] group-hover:scale-105">
                <img
                    className="w-full h-full object-cover"
                    src="/arch2.png"
                    alt="Architecture"
                />
            </div>
        )
    };
}
