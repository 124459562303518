import { useEffect } from 'react';
import { useSessionContext } from 'supertokens-auth-react/recipe/session';
import { setRedirectURL } from '../../constants';
import { useNavigate, useLocation } from 'react-router-dom';
import { useEnterpriseOrganizations } from '../../context-providers/EnterpriseOrganizations';
import HeroExperience from './HeroExperience';
import { lazy, Suspense, useState } from 'react';
import CtaSection from './cta-section';
import FeaturesSection from './FeaturesSection';
import HeroSection from './hero-section';
import FAQ from './faq';
import TestimonialSection from './TestimonialsSection';
import PartnerSection from './PartnerSection';
import PricingSection from '../Pricing/pricing-section';
import Journey from './Journey';

const SphereMask = lazy(() => import('../Components/magicui/sphere-mask'));
const ParticlesEffect = lazy(() => import('./HeroExperience/ParticlesEffect'));
const RadialGradient = lazy(
    () => import('../Components/magicui/RadialGradient')
);

export const LandingPage = () => {
    const sessionContext = useSessionContext();
    const { enterpriseOrganizations } = useEnterpriseOrganizations();
    const navigate = useNavigate();
    const { pathname, hash } = useLocation();
    const [scrollPosition, setScrollPosition] = useState(window.scrollY);

    useEffect(() => {
        if (hash) {
            setTimeout(() => {
                try {
                    const element = document.querySelector(hash);
                    element.scrollIntoView();
                } catch (error) {
                    console.error(error);
                }
            }, 200);
        }
    }, [hash]);

    const onGetStarted = () => {
        let redirectURL = '/projects';
        if (enterpriseOrganizations.length > 0) {
            redirectURL = '/enterprise/dashboard';
        }
        setRedirectURL(redirectURL);
        if (sessionContext.doesSessionExist === true) {
            navigate(redirectURL);
        } else {
            navigate('/auth/?redirectToPath=%2Fprojects');
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const position = window.scrollY;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const shouldLoad = scrollPosition > 0;

    return (
        <div className="bg-black">
            <div className="max-w-screen-xl mx-auto">
                <Suspense fallback={null}>
                    <RadialGradient />
                </Suspense>
            </div>
            <HeroExperience />

            <div className="dark max-w-screen-xl mx-auto">
                <div className="min-h-[700px]">
                    <PartnerSection />
                </div>

                <div className="min-h-[400px]">
                    <HeroSection onGetStarted={onGetStarted} />
                </div>

                <div className="min-h-[2000px]">
                    <Journey />
                </div>

                <div className="min-h-[1500px]">
                    <FeaturesSection />
                </div>

                <div className="min-h-[1000px]">
                    <PricingSection />
                </div>

                <Suspense fallback={null}>
                    <SphereMask />
                </Suspense>

                <div className="min-h-[100px]">
                    <TestimonialSection />
                </div>

                <div className="min-h-[1000px]">{shouldLoad && <FAQ />}</div>

                <div className="min-h-[400px]">
                    {shouldLoad && <CtaSection />}
                </div>
            </div>

            <div className="fixed">
                <Suspense fallback={null}>
                    <ParticlesEffect />
                </Suspense>
            </div>
        </div>
    );
};

export default LandingPage;
