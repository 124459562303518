import { cn } from '../../Components/magicui/lib/utils';
import Marquee from '../../Components/magicui/marquee';
import { StarIcon } from '@heroicons/react/24/solid';
import { ReactNode } from 'react';

export interface TestimonialCardProps {
    name: string;
    quote: ReactNode;
    companyLogo: string;
    companyURL: string;
    className?: string;
    [key: string]: any;
}

export const Highlight = ({
    children,
    className
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <span
            className={cn(
                'bg-cyan-600/20 p-1 py-0.5 font-bold text-cyan-600 dark:bg-cyan-600/20 dark:text-cyan-600',
                className
            )}
        >
            {children}
        </span>
    );
};

export const testimonials: TestimonialCardProps[] = [
    {
        name: 'Nicolas Zander',
        quote: (
            <>
                pre.dev is an awesome tool for mapping out dev ideas.{' '}
                <Highlight>It's mindblowingly good</Highlight>.
            </>
        ),
        companyURL: 'https://www.clevercompliance.io/',
        companyLogo: '/clevercompliance.svg'
    },
    {
        name: 'Max Kryzhanovskiy',
        quote: (
            <>
                I've been using pre.dev for the last month and{' '}
                <Highlight>
                    what used to take us a month or more to plan a client
                    project has gone down to a week
                </Highlight>
            </>
        ),
        companyURL: 'https://moscreative.com/',
        companyLogo: '/moscreative.png'
    },
    {
        name: 'Cynthia Colmenares',
        quote: (
            <>
                What I saw in this tool was, holy moly, this was{' '}
                <Highlight>
                    a phenomenal way to engage with a customer and to get them
                    to understand really what it would take
                </Highlight>
                . Your app visually allows the client to participate and better
                understand whether or not what they want to do is something they
                can afford... too many times they can't, or they need to go out
                for funding or do it in phases.
            </>
        ),
        companyURL: 'https://jackfrostdesign.com/',
        companyLogo: '/jfd.png'
    },
    {
        name: 'Ksenia Snegireva',
        quote: (
            <>
                This pre.dev workflow has{' '}
                <Highlight>
                    allowed us to speed up creating rough prototypes from the
                    requirements
                </Highlight>
                . I'm really impressed and admire the work you've done. It's
                exactly what so many people, including myself, need.
            </>
        ),
        companyLogo: '/ytech.png',
        companyURL: 'https://y-tech.io/main'
    },
    {
        name: 'Gleb Tertychnyi',
        quote: (
            <>
                Fantastic. Just today, I had a problem with our regular form for
                clients. We used to have to push the client to communicate
                through email first, and only after that can we communicate with
                them by phone.{' '}
                <Highlight>
                    This new dev widget has solved these problems.
                </Highlight>
            </>
        ),
        companyLogo: '/wpwp.jpg',
        companyURL: 'https://wpwp.bz/'
    },
    {
        name: 'Saad Affandi',
        quote: (
            <>
                This is a fantastic tool, especially in relation to{' '}
                <Highlight>
                    sales, initial pitches efforts, architecture diagrams, and
                    the work of a business analyst
                </Highlight>{' '}
                who can delegate tasks to the frontend or backend teams.
            </>
        ),
        companyLogo: '/bytecorp.jpg',
        companyURL: 'https://bytecorp.io/'
    },
    {
        name: 'Hossein Fakhr',
        quote: (
            <>
                You know, when we show this visualization and interface to our
                clients,{' '}
                <Highlight>
                    it's been much more interesting than just showing them a
                    simple text or html file. It's a much more engaging process,
                    and the client is absolutely thrilled.
                </Highlight>
            </>
        ),
        companyURL: 'https://www.sdd-technology.com/',
        logoBg: 'bg-black',
        companyLogo: '/sdd.svg'
    },
    {
        name: 'Tiqnia',
        quote: (
            <>
                Yeah, this is interesting because, you know, going back to user
                journeys and user mapping, you see a lot of focus on the
                development side of things, but there's a lot of work that
                happens at a pre-dev level. As a product manager or project
                manager, when you showed me this, I thought,{' '}
                <Highlight>
                    'Oh my God, this is a dream come true for any project or
                    product manager.'
                </Highlight>{' '}
                This is really cool stuff.
            </>
        ),
        companyLogo: '/tiqnia.png',
        companyURL: 'https://tiqniasolutions.co.uk/'
    },
    {
        name: 'Rick Wong',
        quote: (
            <>
                Yeah, James, this is incredible.{' '}
                <Highlight>
                    This tool is much better than the way things are being done
                    now.
                </Highlight>{' '}
                Typically, when I go to a client, they wouldn't want to use
                these tools.
            </>
        ),
        companyLogo: '/datasaa.jpg',
        companyURL: 'https://datasaa.com/'
    }
];
