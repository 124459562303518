import gql from 'graphql-tag';
import {
    jira,
    knowledgeBaseFile,
    loadingStatus,
    message,
    messageUIView,
    sow,
    stripeSubscription,
    uiView,
    uiViewFilesAndErrors
} from '../helper';

export const PROJECT_LOADING_STATUS_SUBSCRIPTION = gql`
	subscription loadingStatusStream($projectId: ID!) {
		loadingStatusStream(projectId: $projectId) {
			${loadingStatus}
		}
	}
`;

export const SOW_SUBSCRIPTION = gql`
    subscription sowStream($projectId: ID!, $type: String!) {
        sowStream(projectId: $projectId, type: $type) {
            ${sow}
        }
    }
`;

export const UIVIEW_SUBSCRIPTION = gql`
    subscription uiviewStream($projectId: ID!, $nodeId: ID!) {
        uiviewStream(projectId: $projectId, nodeId: $nodeId) {
            ${uiView}
            ${uiViewFilesAndErrors}
            chatHistory {
                ${messageUIView}
            }
        }
    }
`;

export const JIRA_SUBSCRIPTION = gql`
    subscription jiraStream($projectId: ID!) {
        jiraStream(projectId: $projectId) {
            ${jira}
        }
    }
`;

export const LINEAR_SUBSCRIPTION = gql`
    subscription linearStream($projectId: ID!) {
        linearStream(projectId: $projectId) {
            ${jira}
        }
    }
`;

export const SUGGESTION_SUBSCRIPTION = gql`
    subscription suggestionStream($projectId: ID!, $randId: ID!) {
        suggestionStream(projectId: $projectId, randId: $randId) {
            suggestion
            completed
        }
    }
`;

export const RECOMMENDED_TECH_STACK_STREAM = gql`
    subscription recommendedTechStackStream($projectId: ID!) {
        recommendedTechStackStream(projectId: $projectId) {
            stack {
                name
                description
                reason
                link
                useFor
                helpfulLinks {
                    url
                    description
                }
            }
            version
        }
    }
`;

export const RECOMMENDED_JOB_ROLES_STREAM = gql`
    subscription recommendedJobRolesStream($projectId: ID!) {
        recommendedJobRolesStream(projectId: $projectId) {
            roles {
                title
                responsibilities
                qualifications
                requiredSkills
            }
            version
        }
    }
`;

export const GRAPH_SUBSCRIPTION = gql`
    subscription graphStream($projectId: ID!) {
        graphStream(projectId: $projectId) {
            _id
            nodes {
                _id
                id
                label
                hours
                description
            }
            edges {
                _id
                source
                target
                description
            }
            side
            projectId
            version
        }
    }
`;

export const FOLLOW_UP_QUESTION_SUBSCRIPTION = gql`
	subscription FollowUpQuestionStream($projectId: ID!, $nodeId: ID) {
		chatStream(projectId: $projectId, nodeId: $nodeId) {
			${message}
		}
	}
`;

export const ADD_EXISTING_PROJECT_CONTEXT_SUBSCRIPTION = gql`
    subscription existingProjectContextStream($existingProjectContextId: ID!) {
        existingProjectContextStream(
            existingProjectContextId: $existingProjectContextId
        )
    }
`;

export const KNOWLEDGE_BASE_FILE_SUBSCRIPTION = gql`
    subscription knowledgeBaseFileStream($fileId: ID!) {
        knowledgeBaseFileStream(fileId: $fileId) {
            ${knowledgeBaseFile}
        }
    }
`;

export const HERO_RANDOM_EXPERIENCE_SUBSCRIPTION = gql`
    subscription heroRandomExperienceStream($randId: String!) {
        heroRandomExperienceStream(randId: $randId) {
            suggestion
            completed
            randId
        }
    }
`;

export const LEADERBOARD_PROJECT_DESCRIPTION_STREAM = gql`
    subscription leaderboardProjectDescriptionStream(
        $projectId: ID!
        $randId: ID!
    ) {
        leaderboardProjectDescriptionStream(
            projectId: $projectId
            randId: $randId
        ) {
            projectId
            suggestion
            completed
        }
    }
`;

export const ARCHITECTURE_PROGRESS_SUBSCRIPTION = gql`
    subscription architectureProgressStream($projectId: ID!) {
        architectureProgressStream(projectId: $projectId) {
            items {
                description
                completed
                progress
            }
        }
    }
`;

export const USER_SUBSCRIPTION_STATUS = gql`
    subscription userSubscriptionStatusStream($userId: ID!) {
        userSubscriptionStatusStream(userId: $userId) {
            ${stripeSubscription}
        }
    }
`;

export const ENTERPRISE_SUBSCRIPTION_STATUS = gql`
    subscription enterpriseSubscriptionStatusStream(
        $enterpriseOrganizationId: ID!
    ) {
        enterpriseSubscriptionStatusStream(
            enterpriseOrganizationId: $enterpriseOrganizationId
        ) {
            ${stripeSubscription}
        }
    }
`;
