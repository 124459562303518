'use client';
import TextShimmer from '../Components/magicui/text-shimmer';
import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import { salesCalendlyLink } from '../../constants';

export default function HeroSection({ onGetStarted }) {
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true, margin: '-100px' });

    return (
        <section
            id="hero"
            ref={ref}
            className="mb-36 dark relative mx-auto sm:mb-52 max-w-[80rem] px-6 text-center md:px-8"
        >
            <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1 }}
                className="backdrop-filter-[12px] inline-flex h-12 items-center justify-between rounded-full border border-white/5 bg-white/10 px-3 text-xs text-white dark:text-black transition-all ease-in hover:cursor-pointer hover:bg-white/20 group gap-1"
            >
                <TextShimmer className="inline-flex items-center justify-center text-sm">
                    <a
                        href="#how-it-works"
                        className="text-white flex items-center gap-2"
                    >
                        <img
                            src="/predev.png"
                            width={36}
                            height={24}
                            className="h-6"
                        />
                        <span className="text-blue-400">pre.dev</span>
                        AI Product Architect
                    </a>{' '}
                    <ArrowRightIcon className="ml-1 h-3 w-3 transition-transform duration-300 ease-in-out group-hover:translate-x-0.5" />
                </TextShimmer>
            </motion.div>
            <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1, delay: 0.2 }}
                className="bg-gradient-to-br dark:from-white from-black from-30% dark:to-white/40 to-black/40 bg-clip-text py-6 text-5xl font-medium leading-none tracking-tighter text-transparent text-balance sm:text-6xl md:text-7xl lg:text-8xl"
            >
                <span className="text-blue-600">pre.dev</span> is the fastest
                way
                <br className="hidden md:block" /> to prototype software
                products.
            </motion.h1>
            <motion.p
                initial={{ opacity: 0, y: 20 }}
                animate={
                    isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                }
                transition={{ duration: 1, delay: 0.4 }}
                className="mb-12 text-lg tracking-tight text-gray-400 md:text-xl text-balance"
            >
                <b>Plan and prototype</b> your product with AI and get it{' '}
                <b> finished by a professional agency</b>.
            </motion.p>
            <div className="flex justify-center">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={
                        isInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }
                    }
                    transition={{ duration: 1, delay: 0.6 }}
                    className="cursor-pointer max-w-xl text-lg leading-normal text-gray-400 lg:text-xl"
                >
                    <a
                        onClick={onGetStarted}
                        style={{ borderRadius: '0.5rem' }}
                        className=" text-primary-foreground shadow inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 opacity-[0.9] mt-4 inline-flex items-center justify-center px-5 py-3 mr-3 font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-primary-900"
                    >
                        Get Started
                        <svg
                            className="w-5 h-5 ml-2 -mr-1"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </a>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ borderRadius: '0.5rem' }}
                        href={salesCalendlyLink}
                        className="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 mt-4 inline-flex items-center justify-center px-5 py-3 font-medium text-center text-white border rounded-lg border-gray-700 hover:bg-gray-700 focus:ring-4 focus:ring-gray-800"
                    >
                        Talk to Sales
                    </a>
                </motion.div>
            </div>
        </section>
    );
}
