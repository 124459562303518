import {
    INITIATE_ENTERPRISE_FOLLOW_UP_QUESTION,
    INITIATE_FOLLOW_UP_QUESTION
} from '../../../api/graphQL';
import { client, clientWithAPIKey } from '../../../api/client';
import { addToChatHistory } from './addToChatHistory';
import {
    fetchChatRequest,
    fetchChatFailure,
    waitingForChatResponse,
    removeLastChat
} from '../../reducers/generic/chatHistory';
import { getSowStatuses } from './getSowStatuses';
import { setWidgetState } from '../../reducers/enterprise_widget/enterpriseWidget';
import { getProjectByLink } from './getProjectByLink';
const apiKey = process.env.REACT_APP_LANDING_PAGE_WIDGET_API_KEY;

// Initializes the chathistory directly and then disables text input
export const handleChatSubmit =
    ({
        answer,
        image_url,
        fileKeyS3,
        projectId,
        userEmail,
        username,
        isWidget,
        isEnterprise
    }) =>
    async (dispatch, getState) => {
        let index = getState().chatHistory.data.length;
        let { firstGeneration } = getState().project.data;
        let answerObj = createAnswer({
            answer,
            username,
            image_url,
            fileKeyS3
        });

        // add latest answer to chat immediately
        dispatch(addToChatHistory({ ...answerObj, index, userEmail }));

        dispatch(waitingForChatResponse(true));

        // then we are going to make the api request with the updated chathistory
        dispatch(fetchChatRequest());

        try {
            let chat;

            if (isEnterprise) {
                const api = getState().enterpriseWidget.apiKey
                    ? getState().enterpriseWidget.apiKey
                    : apiKey;
                let { data } = await clientWithAPIKey(api).mutate({
                    mutation: INITIATE_ENTERPRISE_FOLLOW_UP_QUESTION,
                    variables: {
                        answer: answerObj,
                        apiKey: api,
                        projectId,
                        isWidget
                    }
                });
                chat = data.initiateEnterpriseFollowUpQuestionStream;
            } else {
                let { data } = await client.mutate({
                    mutation: INITIATE_FOLLOW_UP_QUESTION,
                    variables: { answer: answerObj, projectId: projectId }
                });
                chat = data.initiateFollowUpQuestionStream;
            }

            if (index >= 9 && isWidget) {
                dispatch(setWidgetState('projectCompleted'));
            }

            if (index >= 9 && !firstGeneration) {
                dispatch(getProjectByLink(projectId));
            }

            if (getState().project.projectId != projectId) return;

            dispatch(addToChatHistory(chat));
            dispatch(waitingForChatResponse(false));
            dispatch(getSowStatuses(projectId));

            return true;
        } catch (error) {
            if (getState().project.projectId != projectId) return;
            console.log({ error });
            if (error.message.includes(`API is temporarily unavailable.`)) {
                alert(
                    `API is temporarily unavailable. Please try again later.`
                );
            } else alert(error.message);

            dispatch(removeLastChat());
            dispatch(fetchChatFailure(error));
            dispatch(waitingForChatResponse(false));

            return false;
        }
    };

function createAnswer({ answer, username, image_url, fileKeyS3 }) {
    return {
        content: answer,
        role: 'user',
        datetime: new Date().toISOString(),
        username,
        image_url,
        fileKeyS3
    };
}
