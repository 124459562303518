import { AnimatedBeamMultipleInputDemo } from '../../Components/magicui/animated-beam-multiple';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/solid';

export function FinedTunedPlanningAgent() {
    return {
        Icon: AdjustmentsHorizontalIcon,
        name: 'Fine-tuned, AI planning agent',
        description:
            'Our AI pipeline is designed to plan complex software projects with a data-driven approach to timeline forecasting.',
        className: 'col-span-3 lg:col-span-1',
        href: '/auth/?redirectToPath=%2Fprojects',
        cta: 'Get Started',
        background: (
            <div className="absolute h-full w-full border-none transition-all duration-300 ease-out [mask-image:linear-gradient(to_top,transparent_0%,#000_100%)] group-hover:scale-105">
                <AnimatedBeamMultipleInputDemo />
            </div>
        )
    };
}
