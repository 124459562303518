import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    data: [],
    loading: false,
    waitingForChatResponse: false,
    waitingForSuggestion: false,
    error: null
};

export const chatHistorySlice = createSlice({
    name: 'chatHistory',
    initialState,
    reducers: {
        clearStateChatHistory: state => {
            state.data = [];
            state.loading = false;
            state.error = null;
            state.waitingForChatResponse = false;
            state.waitingForSuggestion = false;
        },
        setChatHistory: (state, action) => {
            state.data = action.payload;
            state.loading = false;
        },
        appendChatHistory: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.data = [...state.data, ...action.payload];
            } else if (action.payload) {
                state.data = [...state.data, action.payload];
            }
        },
        removeLastChat: state => {
            state.data = state.data.slice(0, state.data.length - 1);
        },
        waitingForChatResponse: (state, action) => {
            state.waitingForChatResponse = action.payload;
            state.loading = action.payload;
        },
        waitingForSuggestion: (state, action) => {
            state.waitingForSuggestion = action.payload;
        },
        fetchChatRequest: state => {
            state.loading = true;
        },
        appendChat: (state, action) => {
            state.data = [...state.data, action.payload];
        },
        prependChatHistory: (state, action) => {
            if (Array.isArray(action.payload)) {
                state.data = [...action.payload, ...state.data];
            } else if (action.payload) {
                state.data = [action.payload, ...state.data];
            }
        },
        fetchChatFailure: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
        addTagToLastMessage: (state, action) => {
            let tagged = state.data[state.data.length - 1].tagged;
            if (!tagged) {
                state.data[state.data.length - 1].tagged = action.payload;
            } else {
                state.data[state.data.length - 1].tagged +=
                    `,${action.payload}`;
            }
        },
        addTagToMessage: (state, action) => {
            let tagged = state.data.find(
                message => message._id === action.payload.messageId
            ).tagged;
            if (!tagged) {
                state.data.find(
                    message => message._id === action.payload.messageId
                ).tagged = action.payload.userEmail;
            } else {
                state.data.find(
                    message => message._id === action.payload.messageId
                ).tagged += `,${action.payload.userEmail}`;
            }
        }
    }
});

export const {
    setChatHistory,
    removeLastChat,
    waitingForChatResponse,
    fetchChatRequest,
    fetchChatFailure,
    appendChat,
    clearStateChatHistory,
    prependChatHistory,
    waitingForSuggestion,
    addTagToMessage
} = chatHistorySlice.actions;
export const selectChatHistory = state => state.chatHistory;
export const selectWaitingForChatResponse = state =>
    state.chatHistory.waitingForChatResponse;
export const selectWaitingForSuggestion = state =>
    state.chatHistory.waitingForSuggestion;

export default chatHistorySlice.reducer;
