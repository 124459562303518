import { MegaphoneIcon, WindowIcon } from '@heroicons/react/24/outline';

export let basicPlan = {
    id: 'basic',
    name: 'Basic',
    description: 'A free plan to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Basic Product Documentation',
        'Basic Product Roadmap'
    ],
    prototypeGenerations: {
        quantity: '2'
    },
    missingFeatures: ['Unlimited Projects', 'Jira/Linear Integration'],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

export let basicPlanEnterprise = {
    id: 'basic',
    name: 'Basic',
    description: 'A free plan to try the platform',
    features: [
        'No credit card required',
        '2 project limit w/ max 10 chat responses',
        'Basic Product Discovery',
        'Basic Architecture Analysis',
        'Basic Product Documentation',
        'Basic Product Roadmap'
    ],
    prototypeGenerations: {
        quantity: '2'
    },
    missingFeatures: [
        'Unlimited Projects',
        'Jira/Linear Integration',
        'Lead Generation'
    ],
    isMostPopular: false,
    monthlyPrice: 0,
    yearlyPrice: 0,
    textColor: 'text-white'
};

let soloPlusPlan = {
    id: 'SoloPlus',
    name: 'Solo Plus',
    description: 'An intro plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Priority Match with Dev Agencies'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '5'
    },
    monthlyPrice: 1400,
    yearlyPrice: 14000,
    textColor: 'text-blue-400'
};

export let soloPremiumPlan = {
    id: 'SoloPremium',
    name: 'Solo Premium',
    description: 'The ultimate plan for individuals',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Priority Match with Dev Agencies',
        'Jira/Linear Integration'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '25'
    },
    monthlyPrice: 4900,
    yearlyPrice: 49900,
    textColor: 'text-blue-500'
};

let enterprisePremiumPlan = {
    id: 'EnterprisePremium',
    name: 'Enterprise Premium',
    description: 'An enterprise plan made for teams',
    features: [
        'Unlimited Projects',
        'Unlimited Chat',
        'Estimated Budget',
        'Deep Architecture Analysis',
        'Enhanced Product Documentation',
        'Full Product Roadmap',
        'Jira/Linear Integration',
        'Advanced Collaboration Features',
        'Configurable Knowledge Base',
        'Data Security and Compliance'
    ],
    prototypeGenerations: {
        frequency: 'Daily',
        quantity: '25'
    },
    addons: [
        'Lead Gen Database',
        'Lead Gen Widget',
        'Auto Assign Team Members to Tasks'
    ],
    stripeAddOns: [
        {
            id: 'LeadGenPro',
            price: 999,
            title: 'Lead Gen Database + Lead Gen Widget',
            description:
                '100 credits / month to view and connect with qualified leads, including formal introductions by pre.dev.',
            icon: textColor => (
                <MegaphoneIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            ),
            extraDescription:
                'pre.dev takes a 10% comission on all successful contracts',
            halfOff: true
        },
        {
            id: 'WidgetManagement',
            price: 249,
            title: 'Lead Gen Widget',
            description:
                'Seamlessly integrate the pre.dev lead gen widget to streamline client onboarding directly on your website',
            icon: textColor => (
                <WindowIcon
                    className={`h-5 w-5 shrink-0 rounded-full p-[2px] ${textColor}`}
                />
            )
        }
    ],
    monthlyPrice: 9900,
    yearlyPrice: 99900,
    textColor: 'text-green-500',
    perUser: true
};

export const prices = ({ disabledBasic, disabledSolo, disableEnterprrise }) => {
    let plans = [];

    if (!disabledBasic) {
        plans.push(basicPlan);
    }

    if (!disabledSolo) {
        plans.push(soloPlusPlan, soloPremiumPlan);
    }

    if (!disableEnterprrise) {
        plans.push(enterprisePremiumPlan);
    }

    return plans;
};
