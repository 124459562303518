import { GET_UIVIEW_STATUSES } from '../../../api/graphQL/solo/query';
import { client } from '../../../api/client';
import { setUIViewStatuses } from '../../reducers/generic/project';

export const getUIViewStatuses =
    ({ projectId, onlyUpdate }) =>
    async dispatch => {
        try {
            if (!onlyUpdate) {
                dispatch(setUIViewStatuses([]));
            }
            console.log({ projectId });
            const { data } = await client.query({
                query: GET_UIVIEW_STATUSES,
                variables: { projectId },
                fetchPolicy: 'network-only'
            });

            if (data?.getUIViewStatuses) {
                dispatch(setUIViewStatuses(data.getUIViewStatuses));
            }
        } catch (error) {
            console.log({ error });
            if (error.message.includes('API is temporarily unavailable.')) {
                alert(
                    'API is temporarily unavailable. Please try again later.'
                );
            }
        }
    };
