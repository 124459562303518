import { client } from '../../../api/client';
import { UPDATE_USER_IP } from '../../../api/graphQL/solo/mutator';

export const updateUserIp =
    enterpriseOrganizationId => async (dispatch, getState) => {
        try {
            let { data } = await client.mutate({
                mutation: UPDATE_USER_IP,
                variables: {
                    enterpriseOrganizationId
                }
            });

            return data.updateUserIp;
        } catch (error) {
            console.log(JSON.stringify(error, null, 2));
        }
    };
