import { Switch } from '../Components/magicui/ui/switch';
type Interval = 'monthly' | 'yearly';

export const IntervalSwitch = ({
    interval,
    setInterval,
    freeTrialActivated
}: {
    interval: Interval;
    setInterval: (interval: Interval) => void;
    freeTrialActivated: boolean;
}) => (
    <div className="flex w-full items-center justify-center space-x-2">
        <Switch
            id="interval"
            onCheckedChange={checked =>
                setInterval(checked ? 'yearly' : 'monthly')
            }
            defaultChecked={interval === 'yearly'}
        />
        <span className="text-black dark:text-white">Annual</span>
        <span className="ml-2 text-transparent bg-clip-text bg-gradient-to-r from-pink-500 via-purple-500 to-indigo-500 font-semibold">
            2 months free ✨
        </span>
        {freeTrialActivated && (
            <span className="inline-block whitespace-nowrap rounded-full bg-black px-2.5 py-1 text-[11px] font-semibold uppercase leading-5 tracking-wide text-white dark:bg-white dark:text-black">
                1st WEEK FREE ✨
            </span>
        )}
    </div>
);
