import { client } from '../../../api/client';
import { GET_NUM_UIGENERATIONS_LEFT } from '../../../api/graphQL/solo/query';
import { setUIGenerationsLeft } from '../../reducers/generic/profile';

export const getNumUIGenerationsLeft = () => async (dispatch, getState) => {
    try {
        let enterpriseOrganizationId =
            getState()?.enterpriseOrganization?.data?._id;

        const { data } = await client.query({
            query: GET_NUM_UIGENERATIONS_LEFT,
            variables: {
                enterpriseOrganizationId
            },
            fetchPolicy: 'network-only' // Force network request, no caching
        });

        console.log(data.getNumUIGenerationsLeft);

        dispatch(
            setUIGenerationsLeft({
                generationsLeft: data.getNumUIGenerationsLeft.generationsLeft,
                timeUntilReset: data.getNumUIGenerationsLeft.timeUntilReset
            })
        );

        return data.getNumUIGenerationsLeft;
    } catch (error) {
        console.log(JSON.stringify(error, null, 2));
    }
};
