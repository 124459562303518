import { lazy, Suspense } from 'react';
import { cn } from '../../Components/magicui/lib/utils';
import Marquee from '../../Components/magicui/marquee';
import { testimonials } from './testimonials';

const TestimonialCard = lazy(() => import('./card'));

export function TestimonialSection() {
    return (
        <section id="testimonials">
            <div className="py-14 text-center">
                <h2 className="text-3xl text-white font-bold mb-2">
                    What Our Users Say
                </h2>
                <p className="text-lg text-gray-600">
                    Transforming ideas into reality with pre.dev
                </p>
                <div className="container mx-auto px-4 md:px-8">
                    <div className="relative max-h-[650px] overflow-hidden">
                        <div className="gap-4 md:columns-2 xl:columns-3">
                            {Array(Math.ceil(testimonials.length / 3))
                                .fill(0)
                                .map((_, i) => (
                                    <Marquee
                                        vertical
                                        key={i}
                                        className={cn({
                                            '[--duration:60s]': i === 1,
                                            '[--duration:30s]': i === 2,
                                            '[--duration:70s]': i === 3
                                        })}
                                    >
                                        {testimonials
                                            .slice(i * 3, (i + 1) * 3)
                                            .map((card, idx) => (
                                                <Suspense
                                                    key={idx}
                                                    fallback={
                                                        <div className="w-full h-48 animate-pulse bg-gray-200 dark:bg-gray-800 rounded-xl"></div>
                                                    }
                                                >
                                                    <TestimonialCard
                                                        {...card}
                                                    />
                                                </Suspense>
                                            ))}
                                    </Marquee>
                                ))}
                        </div>
                        <div className="pointer-events-none absolute inset-x-0 bottom-0 h-1/4 w-full bg-gradient-to-t from-white from-20% dark:from-black"></div>
                        <div className="pointer-events-none absolute inset-x-0 top-0 h-1/4 w-full bg-gradient-to-b from-white from-20% dark:from-black"></div>
                    </div>
                </div>
            </div>
        </section>
    );
}
export default TestimonialSection;
